// B, A
var k = [ 66, 65,66, 65,66, 65,66, 65,66, 65,66, 65,66, 65],
    n = 0;
$(document).keydown(function (e) {
    if (e.keyCode === k[n++]) {
        if (n === k.length) {
            $("#debug").toggle();
            n = 0;
            return false;
        }
    }
    else {
        n = 0;
    }
});