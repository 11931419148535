const url = '/wp-admin/admin-ajax.php';
const action = 'wish_list_ajax_response';

$(function() {
  // gather wish list buttons
  $('.product-like').each(function(i, button) {
    let $button = $(button);
    let modelId = $button.data('model-id');

    // add class active if model is in wish list
    isInWishList(modelId).then(wished => wished && $button.addClass('active'));

    // add click event listener
    $button.off('click touchstart').on('click touchstart', () => {
      let fct = $button.hasClass('active') ? 'remove' : 'add';
      manageWishList(fct, modelId, $button);
    });
  });

  generateList();
});

/**
 * Remove item from wish list and close menu
 * @param {MouseEvent} e
 * @returns {void}
 */
function onClickDropdownItem(e) {
  e.stopPropagation();
  let modelId = $(e.target)
    .closest('.dropdown-item')
    .data('model-id');
  manageWishList('remove', modelId, $(`.product-like[data-model-id=${modelId}]`));
  $(e.target)
    .closest('.dropdown-menu')
    .dropdown('hide');
}

/**
 * Manage wish list actions
 * @param {string} action - the action to execute
 * @param {number} model - id of the model to add or remove from list
 * @param {jQuery} trigger - the button on witch we want to toggle class active
 * @returns {void}
 */
function manageWishList(action, model, trigger) {
  editWishList(action, model).then(() => {
    action === 'add' ? trigger.addClass('active') : trigger.removeClass('active');
    generateList();
  });
}

/**
 * Fetch wish list and populate list in dropdown menu in header
 * @return {void}
 */
function generateList() {
  getWishList().then(list => {
    const menuIds = ['heartDropdownMenu', 'fixedHeartDropdownMenu'];

    menuIds.forEach(id => {
      let menu = $(`#${id}`);

      // empty the menu so we can easily populate it
      menu.empty();

      if (list.length > 0) {
        list.forEach(item => {
          // create jquery dropdown item
          let $dropdownItem = $(`
            <li class="dropdown-item d-flex flex-row align-items-center" data-model-id="${item.id}">
                <a href="${item.permalink}">
                    <img src="${item.image}" alt="${item.title}" class="mr-1">
                    <span class="mr-3">${item.title}</span>
                </a>
               
                <i class="fas fa-times ml-auto"></i>
            </li>
          `);

          // populate menu with generated items
          menu.append($dropdownItem);

          // add event listener
          // but svg is generated by font awesome so we have to wait
          setTimeout(() => {
            $dropdownItem
              .find('svg')
              .off('click touchstart')
              .on('click touchstart', onClickDropdownItem);
          }, 500);
        });
      } else {
        // list is empty so we add an empty text
        let emptyText = '<li class="dropdown-item">no items in wish list</li>';
        menu.append(emptyText);
      }
    });
  });
}

/**
 * Add or remove model from favorites
 * @param {string} fct - Action to trigger (add, remove, is_favorite, fetch_all)
 * @param {number} id - The model id
 * @return {Promise<any>}
 */
function editWishList(fct, id) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      data: { action, fct, id },
      method: 'POST',
      dataType: 'json',
      success: () => resolve(),
      error: error => reject(error),
    });
  });
}

/**
 * Check if model is in wish list
 * @param {number} id - The model id
 * @returns {Promise<boolean>}
 */
function isInWishList(id) {
  let fct = 'is_favorite';
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      data: { action, fct, id },
      method: 'POST',
      dataType: 'json',
      success: data => resolve(data.is_favorite),
      error: error => reject(error),
    });
  });
}

/**
 * Get user's wish list
 * @returns {Promise<[]>}
 */
function getWishList() {
  let fct = 'fetch_all';
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      data: { action, fct },
      method: 'POST',
      dataType: 'json',
      success: data => resolve(data.wishlist),
      error: error => reject(error),
    });
  });
}
