import compute from 'components/Compute';


$(() => {
    if ($("#paper_form").length){
        $('.choice input[type=checkbox]').on('change', function() {
            console.log('chosen_paper changed');
            $('.choice input[type=checkbox]').not(this).prop('checked', false);
            window.prices.chosen_paper=$(this).data('paperTitle');
            window.prices.chosen_paper_id=$(this).val();
            compute();
        });

        $('select[name=quantity_papier]').on('change', function () {
            console.log('paper_qty changed');
            const v = $(this).val();
            window.prices.paper_qty=v;
            compute();
            if (v == '') {
                $('.hideIfNoQty').addClass('d-none');
                $('.showIfNoQty').removeClass('d-none');
            } else {
                $('.hideIfNoQty').removeClass('d-none');
                $('.showIfNoQty').addClass('d-none');
                $('.prix').each(function () {
                    let priceTable = $(this).data('prices');
                    $(this).text(priceTable[v].prix);
                });
            }

        });

        $('select[name=quantity_papier]').trigger('change');

        $('input[name="round-corner"]').on('change',function(){
            window.prices.round_corner=$(this).is(':checked');
            compute();
        });
    }

});