// Attach header to fixed position on scroll

$(function() {
  lookForScrollPosition();

  $(window).scroll(function() {
    lookForScrollPosition();
  });
});

/**
 * Look for scroll top position
 * If greater than 200 fix header otherwise unfix it
 */
function lookForScrollPosition() {
  if ($(window).outerWidth() < 992) return;

  if ($(window).scrollTop() > 250) {
    $('#toFixOnScroll').addClass('sticky');
  } else {
    $('#toFixOnScroll').removeClass('sticky');
  }
}
