import compute from 'components/Compute';
$(() => {
  if ($('#addressage_form').length) {
    compute();
    $('input[name=back-delivery]')
      .on('change', function () {
        if ($(this).is(':checked')) {
          $('textarea[name=back-delivery-address]')
            .removeClass('d-none')
            .addClass('d-block')
            .prop('required', true);
          $('textarea[name=back-delivery-address]').focus();
          //ss
          $('.versoImage').addClass('d-none');
          $('.versoImage[data-type=addressage]').removeClass('d-none');

          window.prices.addressage_delivery = parseFloat($(this).val());
        } else {
          $('#back-delivery-price').text(0);
          $('textarea[name=back-delivery-address]')
            .addClass('d-none')
            .removeClass('d-block')
            .prop('required', false);
          //
          $('.versoImage').addClass('d-none');
          $('.versoImage[data-type=no_addressage]').removeClass('d-none');

          window.prices.addressage_delivery = 0.0;
        }
        compute();
      })
      .trigger('change');

    $('input[name=front-delivery]')
      .on('change', function () {
        if ($(this).is(':checked')) {
          $('.showIfFrontDelivery').removeClass('d-none');
          $('.showIfNoFrontDelivery').addClass('d-none');

          if ($('input[name=back-delivery]').is(':not(:checked)')) {
            $('input[name=back-delivery]').trigger('click');
          }

          $('input[name=back-delivery]').attr('onclick', 'this.checked=!this.checked;');

          $('.rectoImage').addClass('d-none');
          $('.rectoImage[data-type=addressage]').removeClass('d-none');

          $('.input-type-file-container').removeClass('d-none');
          $('.downloadExisting').removeClass('d-none');

          window.prices.addressage_delivery = 0.0;
          window.prices.addressage_backdelivery = parseFloat($(this).val());
        } else {
          $('.showIfFrontDelivery').addClass('d-none');
          $('.showIfNoFrontDelivery').removeClass('d-none');

          $('.rectoImage').addClass('d-none');
          $('.rectoImage[data-type=no_addressage]').removeClass('d-none');

          $('.input-type-file-container').addClass('d-none');
          $('.downloadExisting').addClass('d-none');

          $('input[name=back-delivery]').attr('onclick', '');
          if ($('input[name=back-delivery]').is(':checked')) {
            window.prices.addressage_delivery = parseFloat($('input[name=back-delivery]').val());
          }
          window.prices.addressage_backdelivery = 0.0;
        }

        compute();
      })
      .trigger('change');

    $('input[name=front-delivery-file]').on('change', function () {
      console.log('click front-delivery-file');
      console.log($(this).val());
      if ($(this).val()) {
        var splitted = $(this)
          .val()
          .split('\\');
        $('#message_file').html(splitted[splitted.length - 1]);
      }
    });
  }
});
