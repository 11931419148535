
$(function () {
    console.log($("#checkout_steps").length);
    console.log('--------------------------------');
    if ($("#checkout_steps").length > 0) {
        $("#checkout_steps a").on('click', function (e) {
            e.preventDefault();
            return false;
        });
    }
    $('select[name="billing_country"],select[name="shipping_country"]').on('change', function () {
        $('body').trigger('update_checkout');
    });
});