import compute from 'components/Compute';

$(() => {
    if ($("#enveloppe_form").length){
        let mainAction=$('form#enveloppe_form').data('mainAction');
        let altAction=$('form#enveloppe_form').data('altAction');
        compute();

        $('select[name=qty_enveloppe]').on('change', function () {
            window.prices.enveloppe_qty = $(this).val()==''?0:parseFloat($(this).val());
            $('#qty-enveloppe').text(qtyEnveloppe);
            if (qtyEnveloppe == 0) {
                $('.showAfterQty').addClass('d-none');
            } else {
                $('.showAfterQty').removeClass('d-none');
            }
            compute();
        }).trigger('change');

        $('input[name=skip-enveloppe]').on('change', function () {
            if ($(this).is(':checked')) {
                window.prices.skip_enveloppe =true;
                $('select[name=qty_enveloppe]').prop('required',false);

                $('input[name="quantity"],input[name="add-to-cart"]').prop('disabled',false);
                $('form#enveloppe_form').attr('action',altAction);

                $('.showIfEnveloppe').addClass('d-none');
            } else {
                window.prices.skip_enveloppe =false;
                $('select[name=qty_enveloppe]').prop('required',true);
                $('input[name="quantity"],input[name="add-to-cart"]').prop('disabled',true);
                $('form#enveloppe_form').attr('action',mainAction);
                $('select[name=qty_enveloppe]').trigger('change');
                $('.showIfEnveloppe').removeClass('d-none');
            }
            compute();
        }).trigger('change');

        $('input[name=enveloppe-patte]').on('change', function () {
            const v = $(this).val();
            console.log('choose patte : ' + v);
            console.log(activePaper);

            $('.versoImage').addClass('d-none');
            $('.versoImage[data-type=' + v + ']').removeClass('d-none');
            window.prices.chosen_enveloppe = v;
            window.prices.enveloppe_unit_price = activePaper['prix_'+v];
            compute();
        });

    }

});