import _ from 'lodash';
import { manageFilter } from '../Slick';

$(() => {
  bindColorSelector($('html'));
});

function onClickColorSelector(e) {
  e.preventDefault();
  console.log('onClickColorSelector');
  const $alternative = $(this).attr('data-alternative');
  const $a = $('.start-personalize');
  const href = _.template($a.data('href'));

  $a.attr('href', href({ alternative: $alternative }));
  $(this)
    .addClass('selected')
    .siblings()
    .removeClass('selected');

  if ($('.slider--single').length > 0) {
    manageFilter($('.slider-for, .slider-nav'), $alternative);
  }
}

function bindColorSelector($scope) {
  console.log('binding');
  $scope
    .find('.product-details .color-selector:not(.realLink)')
    .off('click touchstart')
    .on('click touchstart', onClickColorSelector);
  $scope.find('.product-details .color-selector.selected').trigger('click');
}

export { bindColorSelector };
