import Cropper from 'cropperjs';
import * as composition from '../Customize/composition';
import { changeDpiDataUrl } from 'changedpi/dist';

let aspectRatio;
let croppedImage;
let originalImageB64;
let width;
let height;
let id;
let cropper;

/**
 * Gather original image data, add input type file listener and open modal
 * @param { string } element - Class or id of the modal element
 * @param { Object } originalImage
 * @param { string } originalImage.id - uniqId of the original image
 * @param { number } originalImage.width - Width of the original image
 * @param { number } originalImage.height - Height of the original image
 * @emits handleFileChange
 * @returns { Void }
 */
function openCropperModal(element, originalImage) {
    const input = document.querySelector(`#${element} input[type=file]`);
    input.addEventListener('change', handleFileChange);
    width = originalImage.width;
    height = originalImage.height;
    aspectRatio = width / height;
    id = originalImage.id;
    openModal(`#${element}`);
}

/**
 * Open Bootstrap modal
 * @see {@link https://getbootstrap.com/docs/4.3/components/modal
 * @param { string } element - Class with . or id with # of the modal element
 * @param { Object= } options - Modal options leave empty for defaults
 * @see {@link https://getbootstrap.com/docs/4.3/components/modal/#options}
 * @returns { Void }
 */
function openModal(element, options = {}) {
    $(element).modal(options);
}

/**
 * Close Bootstrap modal
 * @see {@link https://getbootstrap.com/docs/4.3/components/modal
 * @param { string } element - Class or id of the modal element
 * @returns { Void }
 */
function closeModal(element) {
    $(element).modal('hide');
}

/**
 * Event triggered by user file selection
 * @description Show selected file to user and init cropper
 * @param { InputEvent } event - File change event
 * @event handleFileChange
 * @private
 * @returns { Void }
 */
function handleFileChange(event) {
    console.log('handleFileChange');
    // target selected file
    const file = event.currentTarget.files[0];

    // create DOM
    const container = document.createElement('div');
    const image = document.createElement('img');

    // add attributes
    container.classList.add('image-container');
    image.classList.add('img-fluid');
    image.src = window.URL.createObjectURL(file);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
        originalImageB64 = reader.result;
        // console.log(base64data);
        // inject in DOM
        // init cropper
        initCropper(image, originalImageB64);
    }
    container.appendChild(image);

    $(this)
        .closest('.modal-body')
        .find('.modal__cropper')
        .html(container);



}

/**
 * Init cropper and listen to submit
 * @param { HTMLElement } image - The image to crop
 * @emits saveCroppedImage
 * @private
 * @returns { Void }
 */
function initCropper(image, originalImage) {
    image.addEventListener('ready', function () {
        croppedImage = this.cropper
            .getCroppedCanvas({
                width,
                height,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high',
            })
            .toDataURL('image/jpeg', 100);

        croppedImage = changeDpiDataUrl(croppedImage, 300);
    });
    cropper = new Cropper(image, {
        aspectRatio,
        cropend() {
            croppedImage = this.cropper
                .getCroppedCanvas({
                    width,
                    height,
                    imageSmoothingEnabled: true,
                    imageSmoothingQuality: 'high',
                })
                .toDataURL('image/jpeg', 100);

            croppedImage = changeDpiDataUrl(croppedImage, 300);
        },
    });

    $(image)
        .closest('.modal-dialog')
        .find('[type=submit]')
        .off('click touchstart')
        .on('click touchstart', function () {
            saveCroppedImage(croppedImage, originalImage);
        });
}

/**
 * Update base64 image
 * @event saveCroppedImage
 * @private
 * @returns { Void }
 */
function saveCroppedImage(croppedImage, originalImage) {
    composition.updateUserData('image', id, croppedImage, function () {
        console.log('cb');
        composition.updateUserData('originalImage', id, originalImage);
    });
}

export { openModal, closeModal, openCropperModal };
