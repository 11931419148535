import { bindSlicks } from '../Slick';

$(() => {
  bindFilterForm();
});
function bindFilterForm() {
  var $loader = $('#loader-list').clone();
  $loader.removeClass('d-none');
  $('form.filter_form').on('submit', function () {
   
    var data = $(this).serialize();
    $('.grid-products').html($loader);
    $.ajax({
      type: 'GET',
      url: $(this).attr('action'),
      data: data,
      success: function (response) {
        var $response = $(response).find('.grid-products');
        $('.grid-products').html($response.html());
        $('html, body').animate({ scrollTop: $('.grid-products').offset().top }, '300');
        bindSlicks($('.grid-products'));
        bindFilterForm();
      },
    });
    return false;
  });
  $('form.filter_form select').on('change', function () {
    var $form = $(this).closest('form');
    $form.trigger('submit');
  });
}
