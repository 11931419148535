$(function() {
  $('.read-more-toggler')
    .off('click touchstart')
    .on('click touchstart', onClickReadMoreButton);
  $('.read-more-toggler').each(function() {
    initReadMoreToggler($(this));
  });
});

function initReadMoreToggler($button) {
  var $target = $($button.data('target'));
  var closedH = $target.height();
  $target.addClass('open');
  var openenedH = $target.height();
  $target.removeClass('open');
  if (closedH == openenedH) {
    $button.remove();
  }
}
function onClickReadMoreButton(e) {
  e.preventDefault();
  var $button = $(this);
  var $target = $($button.data('target'));
  $target.toggleClass('open');
  if ($target.hasClass('open')) {
    $button.text($button.data('openText'));
  } else {
    $button.text($button.data('closedText'));
  }
  $('html, body').animate({ scrollTop: $target.offset().top - 100 }, 500);
}
