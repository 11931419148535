import 'slick-carousel';
import isMobileDevice from '../../utils/detect-mobile';

let filteredSlick = false;
let $singleSliderFor;
let $singleSliderNav;
let $multipleSliders;

const sliderForOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    accessibility: true,
};

const sliderNavOptions = {
    slidesToShow: 3,
    focusOnSelect: true,
    accessibility: true,
};

$(() => {
    bindSlicks($('body'));
});

function bindSlicks($scope) {
    $singleSliderFor = $scope.find('.slider--single .slider-for');
    if ($singleSliderFor.data('slidesToShow')) {
        sliderForOptions.slidesToShow = $singleSliderFor.data('slidesToShow');
        sliderForOptions.slidesToScroll = $singleSliderFor.data('slidesToShow');
        sliderForOptions.fade = false;
        sliderNavOptions.slidesToShow = $singleSliderFor.data('slidesToShow');
    }
    $singleSliderNav = $scope.find('.slider--single .slider-nav');
    $multipleSliders = $scope.find('.slider--multiple .grid-element');
    $singleSliderFor.slick({
        ...sliderForOptions,
        arrows: false,
        asNavFor: '.slider--single .slider-nav',
    });
    $singleSliderNav.slick({
        ...sliderNavOptions,
        asNavFor: '.slider--single .slider-for',
    });

    if ($multipleSliders.length > 0) {
        $multipleSliders.each(function(index, sliderContainer) {
            if (isMobileDevice()) {
                mobileCategorySlider(sliderContainer);
            } else {
                $(sliderContainer).on('mouseenter', onMouseEnter);
            }
        });
    }
}

function mobileCategorySlider(sliderContainer) {
    const sliderFor = $(sliderContainer).find('.slider-for');
    sliderFor.slick({
        ...sliderForOptions,
        mobileFirst: true,
        arrows: true,
    });

    // filter with selected alternative
    manageFilter(
        sliderFor,
        $(sliderContainer)
        .find('.color-selector.selected')
        .attr('data-alternative'),
    );
    // change href of slider-for on init
    let href = $(sliderContainer)
        .find('.color-selector.selected')
        .attr('data-link');
    sliderFor
        .closest('.grid-element')
        .find('a')
        .attr('href', href);
    // add click listener on color filters
    $(sliderContainer)
        .find('.color-selector')
        .off('click touchstart')
        .on('click touchstart', function() {
            $(this)
                .addClass('selected')
                .siblings()
                .removeClass('selected');
            manageFilter(sliderFor, $(this).attr('data-alternative'));
            href = $(this).attr('data-link');
            sliderFor
                .closest('.grid-element')
                .find('a')
                .attr('href', href);
        });
}

/**
 * @description Filter elements with the alternative passed as argument
 * @param {jQuery} [sliders=$('.slider-for, .slider-nav')] - jQuery elements $('.slider-for, .slider-nav')
 * @param {string} alternative
 * @returns {Void}
 */
function manageFilter(sliders, alternative) {
    // filter
    filterSliders(sliders, alternative);

    // cause we use synced sliders we need to manually reset .slider-for
    const sliderFor = sliders.filter((i, slider) => slider.classList.contains('slider-for'));
    sliderFor.slick('slickGoTo', 0);

    // reassign indexes cause of slick bug when filter on a synced slider
    const sliderNav = sliders.filter((i, slider) => slider.classList.contains('slider-nav'));
    sliderNav.find('.slick-slide:not(.slick-cloned)').each(function(i) {
        $(this).attr('data-slick-index', i);
    });
    sliderNav.slick('slickGoTo', 0);
}

/**
 * @description Unfilter sliders
 * @param {jQuery} sliders - jQuery elements $('.slider-for, .slider-nav')
 * @returns {Void}
 */
function unfilterSliders(sliders) {
    sliders.slick('slickUnfilter');
    filteredSlick = false;
}

/**
 * @description Filer sliders
 * @param {jQuery} sliders - jQuery elements $('.slider-for, .slider-nav')
 * @param {string} alternative
 * @returns {Void}
 */
function filterSliders(sliders, alternative) {
    if (filteredSlick) unfilterSliders(sliders);
    sliders.slick(
        'slickFilter',
        `:has([data-filter-alternative=${alternative}]), :has([data-filter-alternative=global])`,
    );
    filteredSlick = true;
}

/**
 * @description onMouseEnter
 * @returns {Void}
 */
function onMouseEnter() {
    let $container = $(this);
    let $slider = $container.find('.slider-for');
    let $nav = $container.find('.slider-nav');

    // manage listeners
    $container.off('mouseenter').on('mouseleave', onMouseLeave);

    // init sliders
    if ($container.find('.slick-initialized').length === 0) {
        $slider.slick({
            ...sliderForOptions,
            arrows: true,
            asNavFor: $nav,
        });
        $nav.slick({
            ...sliderNavOptions,
            asNavFor: $slider,
        });
    }

    // filter with selected alternative
    manageFilter(
        $container.find('.slider-for, .slider-nav'),
        $container.find('.color-selector.selected').attr('data-alternative'),
    );

    // change href of slider-for on init
    let href = $container.find('.color-selector.selected').attr('data-link');
    $container
        .find('.slider-for')
        .closest('.grid-element')
        .find('a')
        .attr('href', href);

    // add click listener on color filters
    $container
        .find('.color-selector')
        .off('click touchstart')
        .on('click touchstart', function() {
            $(this)
                .addClass('selected')
                .siblings()
                .removeClass('selected');
            manageFilter($container.find('.slider-for, .slider-nav'), $(this).attr('data-alternative'));
            href = $(this).attr('data-link');
            $container
                .find('.slider-for')
                .closest('.grid-element')
                .find('a')
                .attr('href', href);
        });

    // deactivate link on arrows
    /*$container
      .find('.slick-next, .slick-prev')
      .off('click touchstart')
      .on('click touchstart', e => e.preventDefault());*/
}

/**
 * @description Wait for the end of css transition, stop listen to mouseleave and listen to new mouseenter
 * @see /scss/components/_slick.scss .slider-animation-container
 * @returns {Void}
 */
function onMouseLeave() {
    $(this)
        .off('mouseleave')
        .on('mouseenter', onMouseEnter)
        .find('.color-selector')
        .off('click touchstart');
}

export { manageFilter, bindSlicks };