// Change product colour on click onto colour switcher
import * as slick from './slick';
import { initSvgPanZoom } from './svg';
import { openModal } from '../Modal';
import { updateUserData } from './composition';
import { closeModal } from '../Modal/index';


$(function () {
  const customizeSliderFor = $('.customize-modele .slider-for');
  const customZoneLegend = $('.custom-zone');

  if (customizeSliderFor.length > 0) {
    slick.initSlick(customizeSliderFor);
  }

  customZoneLegend.on('mouseenter', onCustomZoneLegendMouseEnter);
  customZoneLegend.on('mouseleave', onCustomZoneLegendMouseLeave);

  let computedHeight = $(window).height();
  $('.fixed-box').each((i, box) => (computedHeight -= $(box).height()));
  $('.zone-custom').css('padding-top', $('.customize-modele .fixed-box').height());
  $('.svg-container').height(computedHeight);

  let svgArray = document.querySelectorAll('.customize-face');
  if (svgArray.length > 0) {
    svgArray.forEach(svg => initSvgPanZoom(svg));
  }

  //  if (localStorage.getItem('customize-found')) {
  //    openModal('#customizeFound');
  //  }

  // Show customize intro modal
  if ($('#customizeIntro').length && $('.only-in-mobile').is(':visible')) {
    openModal('#customizeIntro');
  }

  $('#needHelpModalToggle').on('click touchstart', function () {
    openModal('#needHelp');
  });

  $('.colors a.color-selector.realLink').on('click touchstart', function (e) {
    e.preventDefault();
    var href = $(this).attr('href');
    $('#changeColorConfirm').attr('href', href);
    openModal('#changeColor');
  });

  $('#controlModalToggle').on('click touchstart', function (e) {
    e.preventDefault();
    openModal('#control');
  });

  console.log('comments on submit')
  $('#comments').on('submit', function (e) {
    // Prevent the form from being submitted
    e.preventDefault();

    var comments_not_enough_place = $("#comments_not_enough_place").val();

    console.log(comments_not_enough_place);
    // Submit the form data using AJAX
    updateUserData('text', 'comments_not_enough_place', comments_not_enough_place,
      function () {
        closeModal('#needHelp');
      });

  });
});

function onCustomZoneLegendMouseEnter() {
  toggleHighlightZones();
}

function onCustomZoneLegendMouseLeave() {
  toggleHighlightZones();
}

function toggleHighlightZones() {
  $('.slick-current rect').toggleClass('hover');
}
