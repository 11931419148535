import * as composition from './composition';
import { openCropperModal } from '../Modal';

let clickedElement = null;
let zone = null;
let uniq = null;
let value = null;
let actionType = null;
let input = null;
let characterLimit = null;
let modalId = null;

/**
 * Handle placeholder click event
 * @param { MouseEvent } e - The event
 * @returns { Void }
 */
function handleClick(e) {
  // Init variables
  clickedElement = e.target;
  zone = document.querySelector('#edit-zone');
  uniq = clickedElement.getAttribute('data-uniq');
  actionType = clickedElement.getAttribute('data-type');
  value = clickedElement.getAttribute('data-value');
  input = document.querySelector('[name=customize-text]');
  characterLimit = clickedElement.getAttribute('data-character-limit');
  modalId = clickedElement.getAttribute('data-uniq-cropper');

  initEditZone(e);
}

/**
 * Trigger actions if text or image was clicked
 * @param { MouseEvent } event
 * @returns { Void }
 */
function initEditZone(event) {
  if (actionType === 'text') {
    // ready to type in
    input.focus();

    // listen to user keyboard
    $(input)
      .off('keyup')
      .on('keyup', handleKeyUp);
    $('form#submitText')
      .off('submit')
      .on('submit', handleSubmitText);
    // $("form#submitText input[type=text]").on('blur', handleSubmitText);

    // set value
    if (uniq) {
      input.setAttribute('data-uniq', uniq);
      input.value = value;
    }

    // set text length limit and show it in ui
    if (characterLimit) {
      input.setAttribute('maxlength', characterLimit);
      $('#charCurrent').text(value.length);
      $('#charMax').text(characterLimit);
    }
  } else {
    if ($(`#${modalId} .form-control-file`).val() !== '') {
      $('.text-photo--edit').css('display', 'inline-block');
      $('.text-photo--add').css('display', 'none');
    } else {
      $('.text-photo--add').css('display', 'inline-block');
      $('.text-photo--edit').css('display', 'none');
    }

    // Add event listener to trigger edit image modal
    $(zone)
      .find('[data-edit-type=image] button')
      .off('click touchstart')
      .on('click touchstart', handleEditImage);
  }

  openEditZone();
}

function handleSubmitText(e) {
  e.preventDefault();
  // console.log('submit');
  var $input = $('input[name=customize-text]');

  composition.updateUserData('text', $input.attr('data-uniq'), $input.val());
}
function handleKeyUp(e) {
  $('#charCurrent').text(e.target.value.length);
}

function updateSVGElementDataValue(svg, key, value) {
  svg.find(`[data-uniq=${key}]`).attr('data-value', value);
}

/**
 * Init image data and call openCropperModal
 * @param {MouseEvent} e
 * @returns {void}
 */
function handleEditImage(e) {
  const imageData = {
    id: clickedElement.getAttribute('data-uniq'),
    width: clickedElement.getAttribute('w-crop'),
    height: clickedElement.getAttribute('h-crop'),
  };
  openCropperModal(modalId, imageData);
}

/**
 * Close edit zone
 * @returns {void}
 */
function closeEditZone(e) {
  var $zone = $(zone);
  //if ($zone.find('input[name=customize-text]').is(':visible')){
  //  var $input=$('input[name=customize-text]');
  //  composition.updateUserData('text',$input.attr('data-uniq'), $input.val());
  //}
  $(zone).removeClass('editing');
}

/**
 * Manage Edit zone
 * @returns {void}
 */
function openEditZone() {
  let otherType = actionType === 'text' ? 'image' : 'text';

  // show input
  $(zone)
    .find(`[data-edit-type=${actionType}]`)
    .css('display', 'block');

  // hide other one
  $(zone)
    .find(`[data-edit-type=${otherType}]`)
    .css('display', 'none');

  // open edit zone
  $(zone).addClass('editing');

  // Close edit zone if we click an element that is not editable
  $(document)
    .off('click touchstart')
    .on('click touchstart', function(e) {
      if ($(e.target).attr('data-uniq') === undefined) closeEditZone();
    });
  // Close edit zone is input is blurred
  $(input)
    .off('blur')
    .on('blur', closeEditZone);
}

export { handleClick, updateSVGElementDataValue };
