import * as composition from './composition';

export function initSlick($selector) {
  $('.footer-customize .slider-nav').slick({
    slidesToShow: 2,
    asNavFor: ' .customize-modele .slider-for',
    dots: false,
    focusOnSelect: true,
    accessibility: true,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          mobileFirst: true
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          mobileFirst: true
        },
      },
    ],
  });

  $selector.on('init', function (event, slick) {
    onSlickAfterChange($(slick.$slides.get(0)));
  }).on('afterChange', function (event, slick, currentSlide) {
    onSlickAfterChange($(slick.$slides.get(currentSlide)));
  }).slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.footer-customize .slider-nav',
    prevArrow: '<button class="slide-arrow prev-arrow"></button>',
    nextArrow: '<button class="slide-arrow next-arrow"></button>',
    swipe: false
  });

}

function onSlickAfterChange($CurrentSlideDom) {
  const $face = $CurrentSlideDom.find('.face');
  const $svg = $face.find('svg');

  window.currentFaceData = {
    modelId: $svg.data('modelId'),
    compositionId: $svg.data('compositionId'),
    urlTpl: $svg.data('urlTpl'),
    alternative: $svg.data('alternative'),
    faceId: $svg.data('faceId'),
    $svg: $svg,
    $face: $face
  };
  console.log(window.currentFaceData);
  composition.loadRender($svg.find('image'));
  console.log('Showing face #' + window.currentFaceData.faceId);
}

export default {initSlick}