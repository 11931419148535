import * as svg from './svg';
import _ from 'underscore';
import { closeModal } from '../Modal';
import { updateSVGElementDataValue } from './edit';

let request = false;

function updateUserData(layer, k, v, cb) {

    if (
        typeof cb == 'undefined'
        &&
        !window.currentFaceData.$face.hasClass('loader')
    ) {
        window.currentFaceData.$face.addClass('loader');
    }

    const imageUrl = getImageUrl();
    const data = {
        layer,
        [k]: v
    };
    console.log(data);

    // Fire off the request to /form.phpi
    if (request) request.abort();

    request = $.ajax({
        url: imageUrl,
        type: 'post',
        data: JSON.stringify(data),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
    });

    // Callback handler that will be called on success
    request.done(function () {
        // Log a message to the console

        if (layer === 'image') closeModal('.modal.show');
        if (layer === 'text') updateSVGElementDataValue(window.currentFaceData.$svg, k, v);
        if (typeof cb == 'undefined') {
            loadRender(window.currentFaceData.$svg.find('image'));
        }
        console.log('Hooray, it worked!' + layer);
    });

    // Callback handler that will be called on failure
    request.fail(function (jqXHR, textStatus, errorThrown) {
        // Log the error to the console
        console.error(
            'The following error occurred: ' +
            textStatus, errorThrown
        );
    });

    // Callback handler that will be called regardless
    // if the request failed or succeeded
    request.always(function () {
        // Reenable the inputs
        if (typeof cb === 'function') cb();
    });
}


function getImageUrl(src) {
    if (src) {
        let baseURL = _.template(window.currentFaceData.urlTpl + '&image=<%= image %>');
        return baseURL({
            composition_id: window.currentFaceData.compositionId,
            face: window.currentFaceData.faceId,
            image: src
        }) + '&ts=' + Date.now();
    } else {
        let baseURL = _.template(window.currentFaceData.urlTpl);
        return baseURL({
            composition_id: window.currentFaceData.compositionId,
            face: window.currentFaceData.faceId
        }) + '&ts=' + Date.now();
    }

}

function loadRender($image, src) {
    console.log('loadRender');
    $('.save-indicator').removeClass('d-none');
    $('.save-indicator .fa-spin').removeClass('d-none');
    $('.save-indicator .fa-check').addClass('d-none');
    if (!window.currentFaceData.$face.hasClass('loader')) {
        window.currentFaceData.$face.addClass('loader');

    }
    let imageUrl = '';
    if (src) {
        imageUrl = getImageUrl(src);
    } else {
        imageUrl = getImageUrl();
    }
    //cfr https://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
    //onload event not working on IE... j'ai pris la solution 1 qui passe les tests
    imageToDataURL(imageUrl, function (dataUrl) {
        $image.attr('xlink:href', dataUrl);
        window.currentFaceData.$face.removeClass('loader');
        $('.save-indicator .fa-spin').addClass('d-none');
        $('.save-indicator .fa-check').removeClass('d-none');
    });
    svg.addListeners(window.currentFaceData.$svg);
}

function imageToDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export { updateUserData, loadRender }