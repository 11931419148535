// @nakira As I'm sure you know, this file should only include imports
// Plus there is a utils directory for this kind of functions
Number.prototype.round = function (places) {
  return +(Math.round(this + 'e+' + places) + 'e-' + places);
};
import $ from 'jquery';
// Import styles
//import '../scss/main.scss';
// type definition
import './typeDefinition';

// bootstrap separated modules instead of the whole bootstrap js library
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';

// COMPONENTS
import 'components/StickyHeader';
import 'components/Slick';
import 'components/FontAwesome';

import 'components/ImageSwitcher';
import 'components/Customize';
import 'components/Modal';
import 'components/Customize';
import 'components/MagnificPopup';
import 'components/Radio';
import 'components/Debug';
import 'components/ColorSelector';
import 'components/FormPapier';
import 'components/FormEnveloppe';
import 'components/FormAddressage';
import 'components/WindowHeight';
import 'components/Wishlist';
import 'components/BackToTop';
import 'components/Calendly';
import 'components/ForceFullWidth';
import 'components/ReadMore';
import 'components/Checkout';
import 'components/Navigation';
import 'components/Filter';
