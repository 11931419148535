import * as edit from './edit';
import svgPanZoom from 'svg-pan-zoom/src/svg-pan-zoom';

/**
 * Add event listeners on placeholders
 * @returns { Void }
 */
export function addListeners($svg) {
  $svg
    .find('rect')
    .off('click touchstart')
    .on('click touchstart', edit.handleClick);
}

/**
 * Init svg pan zoom and configure
 * Add event listeners on zoom buttons
 * @param svg - The svg html element
 * @returns { Void }
 */
export function initSvgPanZoom(svg) {
  let panZoomElement = svgPanZoom(svg, {
    zoomEnabled: true,
    panEnabled: true,
    dblClickZoomEnabled: false,
    mouseWheelZoomEnabled: true,
    controlIconsEnabled: false,
    zoomScaleSensitivity: 0.1,
    fit: false,
    center: true,
    minZoom: 0.1,
    onZoom: function(scale) {
      $(svg)
        .closest('.slick-slide')
        .find('[data-zoom=enable]')
        .html(Math.round(scale * 100) + '%');

      if (scale !== 1.0) {
        // show reset option
        panZoomElement.enablePan();
        $(svg)
          .closest('.slick-slide')
          .find('[data-zoom=reset]')
          .css('display', 'inline-block');
      }
    },
  });

  // sometimes input doesn't automatically blur
  svg.addEventListener('click touchstart', function(e) {
    if ($(e.target).attr('data-uniq') === undefined) {
      let input = document.querySelector('[name=customize-text]');
      input.blur();
    }
  });

  // zoom in
  $(svg)
    .closest('.slick-slide')
    .find('[data-zoom=in]')
    .off('click touchstart')
    .on('click touchstart', e => {
      e.preventDefault();
      panZoomElement.zoomIn();
    });

  // zoom out
  $(svg)
    .closest('.slick-slide')
    .find('[data-zoom=out]')
    .off('click touchstart')
    .on('click touchstart', e => {
      e.preventDefault();
      panZoomElement.zoomOut();
    });

  // reset zoom
  $(svg)
    .closest('.slick-slide')
    .find('[data-zoom=reset]')
    .off('click touchstart')
    .on('click touchstart', e => {
      e.preventDefault();
      panZoomElement.resetZoom();
      panZoomElement.resetPan();
      //panZoomElement.disablePan();
      $(svg)
        .closest('.slick-slide')
        .find('[data-zoom=reset]')
        .css('display', 'none');
    });

  // Make sure svg is always centered
  $(window).on('resize', function() {
    let computedHeight = $(window).height();
    $('.fixed-box').each((i, box) => (computedHeight -= $(box).height()));
    $('.zone-custom').css('padding-top', $('.customize-modele .fixed-box').height());
    $('.svg-container').height(computedHeight);

    panZoomElement.resize();
    panZoomElement.center();
  });

  panZoomElement.fit();
  panZoomElement.center();
}
