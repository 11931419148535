// Force full width of element
// needed because element is in container
function forceFullWidth(element) {
    $(element).next().css({
        'margin-top': $(element).outerHeight(true)
    })
}

// on init
$(function() {
    $('.force-full-width').each(function(i, container) {
        forceFullWidth(container);
    });
});

// on resize
window.addEventListener('resize', function () {
    $('.force-full-width').each(function(i, container) {
        forceFullWidth(container);
    });
});
