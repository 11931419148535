// Switch which image to show in large on product page

$(function() {
  // On page load, the first colour choice is the one that's selected and gets an outline
  $('.alt-image:first-of-type > .img-selector').addClass('selected');

  /** Once the user clicks on a colour selected, you remove the selected class from all other selectors and apply it to
   *  the one that was clicked. Then, retrieve the colour of the clicked element and give it an outline in that colour.
   */
  $('.img-selector')
    .off('click touchstart')
    .on('click touchstart', function(e) {
      console.log('image change');
      $('.img-selector').removeClass('selected');
      $(e.target).addClass('selected');
    });
});
