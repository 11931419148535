// Font awesome implementation
// @doc https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use

import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
  faEnvelope,
  faGlobeEurope,
  faTimes,
  faHeart,
  faCamera
} from '@fortawesome/free-solid-svg-icons';

import {
  faInstagram,
  faFacebookF,
  faFlickr,
  faLinkedinIn,
  faPinterestP,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

// We are only using the icons we want to reduce compiled size
library.add(
  faInstagram,
  faFacebookF,
  faEnvelope,
  faGlobeEurope,
  faFlickr,
  faLinkedinIn,
  faPinterestP,
  faTwitter,
  faYoutube,
  faTimes,
  faHeart,
  faCamera
);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();